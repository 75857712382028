import React, { useEffect, useMemo, useState } from 'react';
import {
    AutoComplete,
    Button,
    Checkbox,
    Col,
    Drawer,
    Form,
    Input,
    message,
    Row,
    Select,
} from 'antd';
import { fullPageDrawerZIndex } from 'components/app/layout/AppLayoutUtils';
import { AttributeDefinition } from 'waypoint-types';
import {
    AttributeFormats,
    AttributeTypeCodes,
    AttributeTypes,
    DEFAULT_NUMBER_PRECISION,
    formStyle,
    MAX_NUMBER_DECIMALS,
    reservedDataGridAttributesColumns,
    sanitizeNameIntoAttributeCode,
} from './utils';
import upsertAttributeDefinition from 'waypoint-requests/attributes/upsertAttributeDefinition';
import { GetAttributesV2Response } from 'waypoint-requests/attributes/getAttributesV2';
import { KeyedMutator } from 'swr';
import { stringSort } from 'utils/tables/sorters';

interface AttributesEditDrawerProps {
    isDrawerOpen: boolean;
    setIsDrawerOpen: (value: boolean) => void;
    attributes: AttributeDefinition[];
    mutateAttributes: KeyedMutator<GetAttributesV2Response>;
    selectedAttribute?: AttributeDefinition;
    setSelectedAttribute?: (attribute?: AttributeDefinition) => void;
}

const AttributesEditDrawer = ({
    isDrawerOpen,
    setIsDrawerOpen,
    attributes,
    mutateAttributes,
    selectedAttribute,
    setSelectedAttribute,
}: AttributesEditDrawerProps) => {
    const [form] = Form.useForm();
    const typeWatch = Form.useWatch<AttributeTypeCodes | undefined>(
        'type',
        form,
    );
    const attributeCodeWatch = Form.useWatch('attribute_code', form);

    const [defaultAttribute] = useState<AttributeDefinition | undefined>();

    const updatingAttributeHasValues = useMemo(
        () => !!selectedAttribute?.attributeValues?.length,
        [selectedAttribute],
    );

    useEffect(() => {
        if (selectedAttribute) {
            form.setFieldsValue({
                ...selectedAttribute,
                is_groupable: selectedAttribute.is_groupable ?? false,
                is_filterable: selectedAttribute.is_filterable ?? false,
                is_multiselect: selectedAttribute.is_multiselect ?? false,
            });
        } else {
            form.resetFields();
            form.setFieldsValue({});
        }
    }, [selectedAttribute, form]);

    const isDuplicatedAttributeName = (name: string): boolean => {
        return attributes.some(
            (attr) =>
                (attr.attribute_code === attributeCodeWatch ||
                    reservedDataGridAttributesColumns.includes(
                        attributeCodeWatch,
                    )) &&
                selectedAttribute?.id !== attr.id,
        );
    };

    const handleOnFinish = async (data: AttributeDefinition) => {
        try {
            const isNumberType = data.type === AttributeTypes.number.value;
            const isValidFormat =
                !!data.format && !!AttributeFormats[data.type][data.format];

            const savedAttribute = await upsertAttributeDefinition(
                selectedAttribute
                    ? {
                          ...data,
                          id: selectedAttribute.id,
                          name: data.name.trim(),
                          category: data.category.trim(),
                          format: isValidFormat ? data.format : undefined,
                          precision: isNumberType ? data.precision : undefined,
                          allowed_values:
                              data.allowed_values && data.allowed_values.length
                                  ? data.allowed_values
                                  : null,
                      }
                    : data,
            );

            message.success('Attribute Definition saved successfully');

            form.resetFields();
            form.setFieldsValue({});

            setSelectedAttribute && setSelectedAttribute(undefined);

            await mutateAttributes(
                (currentData: GetAttributesV2Response | undefined) => {
                    if (!currentData) {
                        return currentData;
                    }

                    const updatedAttributes = selectedAttribute?.id
                        ? attributes.map((attr) => {
                              if (attr.id === selectedAttribute?.id) {
                                  return {
                                      ...attr,
                                      ...savedAttribute,
                                  };
                              }
                              return attr;
                          })
                        : [...attributes, savedAttribute];

                    return {
                        ...currentData,
                        attributeDefinitions: updatedAttributes.sort((a, b) =>
                            stringSort(b.name, a.name),
                        ),
                    };
                },
                false, // use cache and avoid server revalidation
            );

            setIsDrawerOpen(false);
        } catch (e: any) {
            if (e instanceof Error) {
                message.error(e.message);
            }
        }
    };

    const onClose = () => {
        form.resetFields();
        form.setFieldsValue({});
        setSelectedAttribute && setSelectedAttribute(undefined);
        setIsDrawerOpen(false);
    };

    const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!selectedAttribute) {
            const name = e.target.value;

            form.setFieldsValue({
                attribute_code: sanitizeNameIntoAttributeCode(name),
            });
        }
    };

    const validFormatsForType = typeWatch
        ? Object.keys(AttributeFormats[typeWatch])
        : [];

    const existingCategories = Array.from(
        new Set(attributes.map((def) => def.category)),
    );

    return (
        <Drawer
            title="Add/Edit Attribute"
            zIndex={fullPageDrawerZIndex}
            placement="right"
            open={isDrawerOpen}
            closable={false}
            onClose={onClose}
        >
            <Form
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 24 }}
                className={formStyle}
                scrollToFirstError
                style={{
                    position: 'relative',
                    height: '100%',
                }}
                form={form}
                layout="vertical"
                onFinish={handleOnFinish}
                name="attribute-definition"
                initialValues={{
                    ...defaultAttribute,
                    is_groupable: defaultAttribute?.is_groupable ?? false,
                    is_filterable: defaultAttribute?.is_filterable ?? false,
                    is_multiselect: defaultAttribute?.is_multiselect ?? false,
                    format: null,
                    precision: DEFAULT_NUMBER_PRECISION,
                }}
            >
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                        {
                            required: true,
                            message: 'Required value',
                        },
                        {
                            validator: (_, value) => {
                                if (value && isDuplicatedAttributeName(value)) {
                                    return Promise.reject(
                                        'This name is already in use.',
                                    );
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Input
                        onChange={(event) => onChangeName(event)}
                        type="text"
                    />
                </Form.Item>

                <Form.Item
                    name="attribute_code"
                    label="Code"
                    rules={[
                        {
                            required: true,
                            message: 'Required value',
                        },
                    ]}
                >
                    <Input type="text" disabled={true} />
                </Form.Item>

                <Form.Item
                    name="category"
                    label="Category"
                    rules={[
                        {
                            required: true,
                            message: 'Required value',
                        },
                    ]}
                >
                    <AutoComplete
                        showSearch={true}
                        filterOption={true}
                        options={existingCategories.map((category) => ({
                            value: category,
                            label: category,
                            key: category,
                        }))}
                    />
                </Form.Item>

                <Form.Item
                    name="type"
                    label="Type"
                    rules={[
                        {
                            required: true,
                            message: 'Required value',
                        },
                    ]}
                >
                    <Select
                        placeholder="Select type"
                        disabled={updatingAttributeHasValues}
                        options={Object.values(AttributeTypes).map(
                            ({ label, value }) => ({
                                label,
                                value,
                            }),
                        )}
                    />
                </Form.Item>

                <Row gutter={10}>
                    {typeWatch === AttributeTypes.number.value && (
                        <Col span={12}>
                            <Form.Item
                                name="precision"
                                label="Precision"
                                rules={[
                                    {
                                        required:
                                            typeWatch ===
                                            AttributeTypes.number.value,
                                        message:
                                            'Precision is required when type is number',
                                    },
                                ]}
                            >
                                <Select
                                    defaultValue={
                                        selectedAttribute?.precision ?? 0
                                    }
                                    options={Array.from(
                                        { length: MAX_NUMBER_DECIMALS + 1 },
                                        (_, i) => i,
                                    ).map((value) => ({
                                        label: value,
                                        value,
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                    )}

                    {!!typeWatch && validFormatsForType.length > 0 && (
                        <Col span={12}>
                            <Form.Item
                                name="format"
                                label="Format"
                                rules={[
                                    {
                                        required:
                                            validFormatsForType.length > 0,
                                        message: 'Format is required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select format"
                                    options={Object.entries(
                                        AttributeFormats[typeWatch],
                                    ).map(([value, type]) => ({
                                        label: `${type.label
                                            .charAt(0)
                                            .toUpperCase()}${type.label.slice(1)}`,
                                        value,
                                    }))}
                                    defaultValue={
                                        selectedAttribute?.format ?? null
                                    }
                                />
                            </Form.Item>
                        </Col>
                    )}
                </Row>

                <Form.Item
                    name="is_groupable"
                    valuePropName="checked"
                    style={{ marginBottom: '0' }}
                >
                    <Checkbox>Groupable</Checkbox>
                </Form.Item>

                <Form.Item
                    name="is_filterable"
                    valuePropName="checked"
                    style={{ marginBottom: '0' }}
                >
                    <Checkbox>Filterable</Checkbox>
                </Form.Item>

                {typeWatch !== AttributeTypes.boolean.value && (
                    <Form.Item name="is_multiselect" valuePropName="checked">
                        <Checkbox disabled={updatingAttributeHasValues}>
                            Multi-select
                        </Checkbox>
                    </Form.Item>
                )}

                {typeWatch === AttributeTypes.string.value && (
                    <Form.Item name="allowed_values" label="Allowed values">
                        <Select placeholder="Allowed values" mode="tags" />
                    </Form.Item>
                )}

                <Form.Item
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: '10px',
                        }}
                    >
                        <Button
                            size="middle"
                            onClick={onClose}
                            style={{ marginRight: '10px' }}
                        >
                            Cancel
                        </Button>
                        <Button size="middle" type="primary" htmlType="submit">
                            {selectedAttribute?.id ? 'Update' : 'Save'}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default AttributesEditDrawer;
