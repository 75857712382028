// @ts-nocheck
// This file is a result of nx update

import { PROPERTY_CACHE_KEY } from 'config/constants';
import { identity, pick, union, get, replace } from 'lodash';
import { createSelectorCreator } from 'reselect';
import { getPureId } from 'state/routing/selectors';
import { addOldCacheKey } from 'state/utils';

// Cripple the memoization of selectors in this file
const createSelector = createSelectorCreator(identity);

// Usage:
// function mapState(state, props) {
//     const newProps = merge(props, { propertyIds: ['1', 2, 3] })
//     return {
//         properties: selectPropertiesById(state, newProps),
//     }
// }
export const selectUsersWithAccess = (state, { propertyId }) => {
    const property = state.properties[PROPERTY_CACHE_KEY + propertyId];
    if (property.usersWithAccess) {
        return property.usersWithAccess;
    }
    return null;
};
export const selectProperties = (state) => state.properties;
export const idsFilter = (state, props) => {
    return union(
        state ? state.propertyIds : state,
        props ? props.propertyIds : props,
    );
};

// idsFilter allows connected react component or selector flow to select propertyIds
export const idFilter = (_, props) => props.propertyId;

export const groupIdFilter = (_, props) => props.propertyGroupId;

export const selectPropertiesById = createSelector(
    selectProperties,
    idsFilter,
    (properties, propertyIds) => {
        const oldPropertyIds = propertyIds.map(
            addOldCacheKey(replace(PROPERTY_CACHE_KEY, '_', '')),
        );

        return pick(properties, oldPropertyIds);
    },
);

export const isProperty = (state) =>
    !!get(state, 'routing.locationBeforeTransitions.pathname').includes(
        'property',
    );

export const selectPropertyName = (state) =>
    get(
        state,
        `properties.${PROPERTY_CACHE_KEY + getPureId(state)}.display_name`,
    );

export const selectPropertyPhoto = (state, propertyId) =>
    get(
        state,
        `properties.${PROPERTY_CACHE_KEY + propertyId}.entityPhoto`,
        null,
    );
