import React, { useState, useEffect, useRef } from 'react';
import Skeleton from 'antd/lib/skeleton';
import { isEqual } from 'lodash';
import { getIncomeStatementSubheading } from 'components/comments';
import { saveAs } from 'file-saver';
import { getComparativeIncomeStatementDownload } from 'waypoint-requests';
import { getDefaultFieldValuesFor } from 'components/financials/comparative-income-statement/banner/ComparisonSelectionsBannerUtils';
import {
    FINANCIAL_YEAR_ENDING,
    WORKFLOW_REVIEWER,
} from 'components/financials/comparative-income-statement/constants';
import { getFilenameFromResponse } from 'waypoint-utils';
import { COMPARATIVE_INCOME_STATEMENT_FEATURE_NAME } from 'components/financials/comparative-income-statement/constants';
import { DisabledDashboard } from 'waypoint-react';
import {
    ComparativeIncomeStatementTable,
    Settings,
    ComparisonSelectionsBanner,
} from 'components/financials/comparative-income-statement';
import { cx } from 'emotion';
import { FixAnyType, PropertyType } from 'waypoint-types';
import { connect, RootStateOrAny } from 'react-redux';
import { cardStyle } from 'components/financials/common/';
import { useIncomeStatement } from 'components/financials/comparative-income-statement/IncomeStatementProvider';
import { roles as userRoles } from 'config/constants';
import { selectProperties } from 'state/properties/selectors';
import { Dictionary } from 'ts-essentials';
import { useCommentsPane } from 'contexts/comments/CommentsContext';
import { Button, Card, message } from 'antd';
import {
    useGetMentionableUsers,
    useGetRSFAndUnits,
    useWorkflowReport,
} from 'waypoint-hooks';
import {
    WorkflowAvatarBannerGroup,
    WorkflowModal,
} from 'components/financials/workflow-roles';
import { WorkflowStatus } from '../menu/WorkflowStatus';
import WorkflowReportSelectionBanner from '../banner/WorkflowReportSelectionBanner';
import { useCommentsContextManager } from 'contexts/comments/CommentsManagerContext';

interface ComparativeIncomeStatementCardProps {
    isAdmin: boolean;
    userId: number;
    properties: Dictionary<PropertyType> | undefined;
    isPropertyProfile: boolean;
    isVarianceReporting?: boolean;
}

export const ComparativeIncomeStatementCard = ({
    isAdmin,
    userId,
    properties,
    isVarianceReporting,
    isPropertyProfile,
}: ComparativeIncomeStatementCardProps): JSX.Element => {
    const [isBannerCollapsed, setIsBannerCollapsed] = useState<boolean>(false);
    const [propertyDisplayName, setPropertyDisplayName] = useState<
        string | null
    >(null);

    const [selectedAccountMappingId, setSelectedAccountMappingId] = useState<
        string | null
    >(null);
    const [isOpenSettings, setIsOpenSettings] = useState<boolean>(false);
    const [isDownloadingSpreadsheet, setIsDownloadingSpreadsheet] =
        useState<boolean>(false);

    const {
        incomeStatementNodes,
        entityCodes: selectedEntityCodes,
        selectedDataLevel,
        asOfDate,
        reportMetadata,
        commentIdQueryParam,
        reportMetadataIdQueryParam,
        hideNull,
        showPerSF,
        showPerUnit,
        setFilterOverThreshold,
        hasRequiredWorkflowThreshold,
        thresholdCalculations,
        hideNotes,
        comparisonSelections,
        setSelections,
        reportMetadataFilters,
        clientModes,
        mentionableAccounts: accountMentionOptions,
        isLoading: isLoadingIncomeStatementData,
        isError: isErrorLoadingIncomeStatementData,
        varianceReportQueryParams,
        showCurrentPeriod,
        clientFinancialYearEnding,
    } = useIncomeStatement();

    const commentPaneCtx = useCommentsPane();

    const {
        update: updateCommentsPane,
        openComments,
        closeComments,
        initializeComments,
        commentThreadAccountMentions,
        isVisible,
    } = commentPaneCtx;

    const { registerCommentThread } = useCommentsContextManager();

    const entityCodeCommentIdRef = useRef<string[] | null>(null);
    const entityCodesRef = useRef(selectedEntityCodes);

    const componentKey = useRef(new Date().toISOString());

    useEffect(() => {
        if (isPropertyProfile) {
            registerCommentThread(commentPaneCtx, componentKey.current);
        }
    }, [commentPaneCtx]);

    useEffect(() => {
        if (
            entityCodeCommentIdRef.current &&
            !isEqual(entityCodeCommentIdRef.current, entityCodesRef.current) &&
            isVisible
        ) {
            closeComments();
            entityCodeCommentIdRef.current = entityCodesRef.current;
        }
    }, [selectedEntityCodes, closeComments, isVisible]);

    if (!isEqual(entityCodesRef.current, selectedEntityCodes)) {
        entityCodesRef.current = selectedEntityCodes;
    }

    const entityCodes = entityCodesRef.current;

    const { data: rsfAndUnits } = useGetRSFAndUnits(entityCodes);

    const userMentionOptions = useGetMentionableUsers(entityCodes);

    useEffect(() => {
        updateCommentsPane({
            userMentionOptions,
        });
    }, [userMentionOptions]);

    const {
        selectedWorkflowRole,
        workflowModalIsOpen,
        setWorkflowModalIsOpen,
        handleWorkflowModal,
        workflowRoles,
        mutateWorkflowRoles,
        workflowReportStatus,
        getUserDisplayNameById,
        updateReportStatus,
        userWorkflowRole,
    } = useWorkflowReport({
        entityCodes,
        reportMetadata,
        userId,
        propertyDisplayName,
        userMentionOptions: userMentionOptions ?? [],
    });

    useEffect(() => {
        if (entityCodes.length > 1) {
            closeComments();
        }
    }, [entityCodes]);

    useEffect(() => {
        if (!reportMetadata?.comment_thread_id || !entityCodes.length) {
            return;
        }

        initializeComments(reportMetadata?.comment_thread_id);

        updateCommentsPane({
            heading: 'Income Statement',
            scrollToCommentId: commentIdQueryParam ?? undefined,
        });

        if (commentIdQueryParam && entityCodes.length === 1) {
            openComments();
            entityCodeCommentIdRef.current = entityCodesRef.current;
        }
    }, [
        reportMetadata?.comment_thread_id,
        entityCodes.length,
        commentIdQueryParam,
    ]);

    useEffect(() => {
        updateCommentsPane({
            accountMentionOptions,
        });
    }, [accountMentionOptions]);

    useEffect(() => {
        updateCommentsPane({
            accountGraph: incomeStatementNodes,
        });
    }, [incomeStatementNodes]);

    useEffect(() => {
        updateCommentsPane({
            subheading:
                comparisonSelections && clientModes
                    ? getIncomeStatementSubheading(
                          comparisonSelections,
                          clientModes,
                      )
                    : '',
            financialYearEnding:
                comparisonSelections !== null
                    ? comparisonSelections[FINANCIAL_YEAR_ENDING]
                    : null,
        });
    }, [comparisonSelections]);

    const updatePropertyDisplayName = () => {
        const displayNameKey = `PropertySlim_${entityCodes[0]}`;
        setPropertyDisplayName(
            properties ? properties[displayNameKey].display_name : null,
        );
    };

    useEffect(() => {
        if (
            entityCodes.length === 1 &&
            (hasRequiredWorkflowThreshold || reportMetadataIdQueryParam)
        ) {
            updatePropertyDisplayName();
        }
    }, [entityCodes, hasRequiredWorkflowThreshold, reportMetadataIdQueryParam]);

    const downloadCsv = async () => {
        setIsDownloadingSpreadsheet(true);

        try {
            const response = await getComparativeIncomeStatementDownload({
                download: true,
                periods: reportMetadataFilters,
                entityCodes,
                isHideNull: hideNull,
                reportMetadataId:
                    reportMetadata && !hideNotes ? reportMetadata.id : null,
                totalRSF: showPerSF
                    ? rsfAndUnits?.rentable_square_footage ?? 0
                    : undefined,
                totalUnits: showPerUnit
                    ? rsfAndUnits?.total_units ?? 0
                    : undefined,
                showCurrentPeriod,
                selectedDataLevel,
            });

            const filename = getFilenameFromResponse(response);

            saveAs(await response.blob(), filename);
        } catch (e) {
            message.error('An error occurred while downloading the file');
        } finally {
            setIsDownloadingSpreadsheet(false);
        }
    };

    const resetSelections = () => {
        if (asOfDate) {
            setSelections({
                ...getDefaultFieldValuesFor(
                    asOfDate,
                    clientFinancialYearEnding?.value,
                ),
            });
        }

        setFilterOverThreshold(false);
    };

    const titleCard = !isVarianceReporting ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <h2 style={{ marginBottom: 0, marginTop: 0 }}>
                {COMPARATIVE_INCOME_STATEMENT_FEATURE_NAME}
            </h2>
            <Button
                disabled={isLoadingIncomeStatementData || !comparisonSelections}
                style={{ marginLeft: '14px' }}
                type="primary"
                data-testid="new-comparison-button-modal"
                onClick={() => setIsOpenSettings(true)}
            >
                New Comparison
            </Button>
        </div>
    ) : null;

    const heightOffset = isPropertyProfile ? 300 : 250;

    const isSmallScreen = window.innerWidth < 1024 || window.innerHeight < 900;

    if (isLoadingIncomeStatementData || !comparisonSelections || !rsfAndUnits) {
        return (
            <Card
                className={cardStyle}
                data-testid="component-cis-card"
                size="default"
                title={titleCard}
                extra={<> </>}
            >
                <div style={{ height: 'calc(100vh - 268px)', padding: 20 }}>
                    <Skeleton paragraph={{ rows: isSmallScreen ? 4 : 12 }} />
                </div>
            </Card>
        );
    }

    if (isErrorLoadingIncomeStatementData) {
        return (
            <DisabledDashboard
                text={'There was an error loading your income statement data.'}
            />
        );
    }

    if (!entityCodes.length) {
        return <DisabledDashboard text={'No properties selected'} />;
    }

    if (!clientModes?.length) {
        return <DisabledDashboard text={'No modes selected'} />;
    }

    if (
        isVarianceReporting &&
        (!varianceReportQueryParams.periodicity ||
            !varianceReportQueryParams.mode ||
            !varianceReportQueryParams.endDate)
    ) {
        return (
            <DisabledDashboard
                text={'There was an error loading variance report'}
            />
        );
    }

    const workflowModal = workflowModalIsOpen && selectedWorkflowRole && (
        <WorkflowModal
            data-testid="cis-workflow-modal"
            workflowRoleList={
                selectedWorkflowRole === WORKFLOW_REVIEWER
                    ? workflowRoles?.reviewers ?? []
                    : workflowRoles?.assignees ?? []
            }
            mutateWorkflowRoles={mutateWorkflowRoles}
            reportRole={selectedWorkflowRole}
            setWorkflowModalIsOpen={setWorkflowModalIsOpen}
            userOptions={
                userMentionOptions?.map((item) => {
                    return {
                        value: Number(item.id),
                        label: item.text,
                    };
                }) ?? []
            }
            entityCode={entityCodes[0]}
            reportType="comparative-income"
        />
    );

    const workflowAvatarBannerGroup = (
        <WorkflowAvatarBannerGroup
            data-testid="cis-workflow-avatar-group"
            isAdmin={isAdmin}
            handleWorkflowModal={handleWorkflowModal}
            workflowAssignees={workflowRoles?.assignees ?? []}
            workflowReviewers={workflowRoles?.reviewers ?? []}
        />
    );

    const workflowStatus = (className?: string) => {
        return (
            <WorkflowStatus
                userWorkflowRole={userWorkflowRole}
                displayReportStatus={
                    hasRequiredWorkflowThreshold && entityCodes.length === 1
                }
                workflowReportStatus={workflowReportStatus}
                updateReportStatus={updateReportStatus}
                getUserDisplayNameById={getUserDisplayNameById}
                className={className}
            />
        );
    };

    return (
        <Card
            id="financials-income-statement"
            className={cx([cardStyle])}
            headStyle={
                hasRequiredWorkflowThreshold
                    ? {
                          width: '100%',
                      }
                    : {}
            }
            data-testid="component-cis-card"
            size="default"
            title={titleCard}
            extra={
                !isVarianceReporting ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ComparisonSelectionsBanner
                            data-testid="comparison-select-banner"
                            selections={comparisonSelections}
                            resetSelections={resetSelections}
                            entityCodes={entityCodes}
                            isBannerCollapsed={isBannerCollapsed}
                            thresholdCalculations={thresholdCalculations}
                            isOpenSettings={isOpenSettings}
                            setIsOpenSettings={setIsOpenSettings}
                        />
                        <Settings
                            isCollapsible={false}
                            loading={!asOfDate || isDownloadingSpreadsheet}
                            downloadCsv={downloadCsv}
                            isBannerCollapsed={isBannerCollapsed}
                            onCollapseBanner={() =>
                                setIsBannerCollapsed(!isBannerCollapsed)
                            }
                        />
                    </div>
                ) : null
            }
            style={isPropertyProfile ? { height: '84vh' } : undefined}
        >
            {!asOfDate ? (
                <div style={{ padding: 20 }}>
                    <Skeleton paragraph={{ rows: 15 }} />
                </div>
            ) : (
                <>
                    {isVarianceReporting ? (
                        <WorkflowReportSelectionBanner
                            data-testid="workflow-report-select-banner"
                            workflowModal={workflowModal}
                            workflowAvatarBannerGroup={
                                workflowAvatarBannerGroup
                            }
                            workflowStatus={workflowStatus()}
                            propertyDisplayName={propertyDisplayName}
                            downloadCsv={downloadCsv}
                        />
                    ) : null}

                    <ComparativeIncomeStatementTable
                        entityCodes={entityCodes}
                        selections={comparisonSelections}
                        selectedAccountMappingId={
                            selectedAccountMappingId ?? ''
                        }
                        setSelectedAccountMappingId={
                            setSelectedAccountMappingId
                        }
                        commentThreadAccountMentions={
                            commentThreadAccountMentions ?? []
                        }
                        setAccountFilterSelection={(
                            accountFilterSelection: FixAnyType,
                        ) => {
                            updateCommentsPane({
                                accountFilterSelection,
                            });
                        }}
                        modes={clientModes}
                        metadataId={reportMetadata?.id ?? ''}
                        heightOffset={heightOffset}
                        totalRSF={rsfAndUnits.rentable_square_footage}
                        totalUnits={rsfAndUnits.total_units}
                    />
                </>
            )}
        </Card>
    );
};

const mapState = (s: RootStateOrAny) => {
    const properties = selectProperties(s);

    const { roles } = s.user;
    const isAdmin = roles.includes(userRoles.CLIENT_ADMIN);
    const userId = s.user.id;

    return {
        isAdmin,
        userId,
        properties,
    };
};

export default connect(mapState)(ComparativeIncomeStatementCard);
