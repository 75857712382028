import React from 'react';
import {
    EntityDataGroupingKeys,
    entityDataGroupingOptions,
} from 'utils/EntityDataGroupingConstants';
import { AttributeFromAPI } from 'contexts';
import { stringSort } from 'utils/tables/sorters';
import { Button, Dropdown, MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';

export interface AttributesGroupBySelectProps {
    groupingSelection: EntityDataGroupingKeys;
    setGroupingSelection: (option: EntityDataGroupingKeys) => void;
    attributeSelection: AttributeFromAPI | null;
    attributes: AttributeFromAPI[] | undefined;
    setAttributeSelection: (option: AttributeFromAPI) => void;
}

export const AttributesGroupBySelect = ({
    groupingSelection,
    setGroupingSelection,
    attributeSelection,
    attributes,
    setAttributeSelection,
}: AttributesGroupBySelectProps) => {
    if (!attributes || !attributeSelection) {
        return null;
    }

    const attributeOptions = attributes
        .map((attribute) => ({
            value: attribute.dataIndex,
            label: attribute.title,
        }))
        .sort((a, b) => stringSort(b?.label, a?.label));

    const groupByValueOptionsByGroupByType = {
        [EntityDataGroupingKeys.Attributes]: attributeOptions,
        [EntityDataGroupingKeys.Property]: [],
    };

    const onGroupByValueChanged = (newValue: string) => {
        const attribute = attributes.find(
            (attribute) => attribute.dataIndex === newValue,
        );

        if (attribute) {
            setAttributeSelection(attribute);
        }
    };

    const items = entityDataGroupingOptions.map((typeOption) => {
        if (typeOption.value === 'property') {
            return {
                key: typeOption.value,
                label: typeOption.label,
            };
        }

        const childOptions =
            groupByValueOptionsByGroupByType[typeOption.value]?.map(
                (valueOption) => ({
                    key: valueOption.value,
                    label: valueOption.label,
                }),
            ) || [];

        return {
            key: typeOption.value,
            label: typeOption.label,
            children: childOptions,
            selectable: true,
        };
    });

    const onClick: MenuProps['onClick'] = (e) => {
        const containsProperty = e.keyPath.includes('property');

        if (containsProperty) {
            setGroupingSelection(EntityDataGroupingKeys.Property);
            return;
        }

        setGroupingSelection(EntityDataGroupingKeys.Attributes);
        onGroupByValueChanged(e.key as string);
    };

    return (
        <Dropdown
            trigger={['hover']}
            menu={{
                items,
                selectable: true,
                onClick,
                selectedKeys: [groupingSelection],
            }}
        >
            <Button
                style={{
                    textAlign: 'left',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 15px',
                    width: 195,
                }}
                onClick={(e) => e.preventDefault()}
                data-testid="group-by-dropdown"
            >
                <span>
                    {groupingSelection
                        ? groupingSelection ===
                          EntityDataGroupingKeys.Attributes
                            ? attributeSelection?.title
                            : 'Property'
                        : 'Select'}
                </span>
                <DownOutlined />
            </Button>
        </Dropdown>
    );
};
