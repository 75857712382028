import React, { useCallback, useEffect } from 'react';
import { useIncomeStatementSettings } from 'waypoint-hooks';
import {
    getComparisonPeriodSelect,
    getComparisonTypeSelect,
    getPeriodicitySelect,
    getPeriodRangeSelect,
    getVarianceComparisonOptions,
    getVarianceOptions,
    getYearEndingSelect,
    PRIMARY_COLUMN_INDEX,
    SECONDARY_COLUMN_INDEX,
    TERTIARY_COLUMN_INDEX,
} from 'components/financials/comparative-income-statement/selections/ComparativeIncomeStatementSelectionUtils';
import {
    buildStringToComparisonTypeString,
    getDefaultFieldValuesFor,
} from 'components/financials/comparative-income-statement/banner/ComparisonSelectionsBannerUtils';
import {
    COMPARISON_TYPE,
    DISABLED_VALUE,
    NONE_VALUE,
    PERIODICITY_FIELD,
    PERIOD_FIELD_NAME_PRIMARY,
    VARIANCE_DISPLAY,
    VARIANCE_DISPLAY_TERTIARY,
} from 'components/financials/comparative-income-statement/constants';
import { Button, Modal } from 'antd';
import { css } from 'emotion';
import theme from 'config/theme';
import { isEqual } from 'lodash';
import { ComparisonSelections } from '../ComparisonIncomeStatementTypes';
import moment from 'moment';
import { toEndOfMonth, toISO } from 'components/dates/utils';

interface IncomeStatementMenuProps {
    isOpen: boolean;
    onClose: () => void;
    entityCodes: string[];
    selections: any;
    resetSelections: () => void;
    setSelections: (selections: any) => void;
    onClearFilters: () => void;
}

const buttonStyle = css`
    margin-right: 24px;
    &.ant-btn-primary:enabled {
        color: ${theme.colors.white} !important;
        background-color: ${theme.colors.blues.primary} !important;
        border-color: ${theme.colors.blues.outline} !important;
    }
`;

const modalStyle = css`
    width: max-content !important;
    .ant-modal-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
            margin: 0 !important;
        }
    }
    .ant-modal-footer {
        margin-top: 24px !important;
        text-align: right;
    }
`;

const IncomeStatementMenu = ({
    isOpen,
    onClose,
    entityCodes,
    selections,
    resetSelections,
    setSelections,
    onClearFilters,
}: IncomeStatementMenuProps) => {
    const {
        asOfDate,
        localSelections,
        setLocalSelections,
        getUserSelectionFor,
        localModes,
        handleOnChange,
        rangeSelectIsOpen,
        setRangeSelectIsOpen,
        handleOnChangeDateRange,
        comparisonTypeOptions,
        comparisonTypeOptionsWithNone,
        clientFinancialYearEnding,
    } = useIncomeStatementSettings(entityCodes);

    useEffect(() => {
        setLocalSelections({ ...selections });
    }, [JSON.stringify(selections)]);

    useEffect(() => {
        calculateDateRangeOnFinancialYearEndingChange();
    }, [localSelections?.financial_year_ending]);

    const calculateDateRangeOnFinancialYearEndingChange = () => {
        if (localSelections) {
            const periodEnding = moment(
                getUserSelectionFor(PERIOD_FIELD_NAME_PRIMARY)[1]
            );
            if (periodEnding) {
                handleOnChangeDateRange(toISO(toEndOfMonth(periodEnding)));
            }
        }
    };

    const selectionsAreUnchangedOrDefault = useCallback(
        (isReset = false) => {
            if (isReset) {
                return isEqual(
                    getDefaultFieldValuesFor(
                        asOfDate as Date,
                        clientFinancialYearEnding?.value
                    ),
                    localSelections
                );
            }
            return isEqual(selections, localSelections);
        },
        [localSelections]
    );

    const saveSelections = () => {
        const newSelections = handleOnChangeDateRange(
            getUserSelectionFor(PERIOD_FIELD_NAME_PRIMARY)[1]
        ) as ComparisonSelections;

        setSelections(newSelections);
        onClearFilters();
    };

    const reset = () => {
        setLocalSelections({
            ...getDefaultFieldValuesFor(
                asOfDate as Date,
                clientFinancialYearEnding?.value
            ),
        });
        resetSelections();
        onClearFilters();
        onClose();
    };

    const showYearEndingSelect = () => {
        return !['trailing_12', 'custom'].includes(
            getUserSelectionFor(PERIODICITY_FIELD)
        );
    };

    const isVarianceComparisonSelectDisabled = (
        varianceDisplay: string,
        isTertiary = false
    ) => {
        const isNoneSecondComparisonValueSelected =
            getUserSelectionFor(COMPARISON_TYPE)[isTertiary ? 2 : 1] ===
            NONE_VALUE;
        const isDisabledVarianceDisplayTertiarySelected =
            getUserSelectionFor(varianceDisplay) === DISABLED_VALUE;
        return (
            isNoneSecondComparisonValueSelected ||
            isDisabledVarianceDisplayTertiarySelected
        );
    };

    return (
        <Modal
            className={modalStyle}
            title={<h2>Income Statement Settings</h2>}
            open={isOpen}
            onCancel={onClose}
            footer={[
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginLeft: '32px',
                        marginRight: '32px',
                    }}
                >
                    <Button
                        className={buttonStyle}
                        type="primary"
                        onClick={reset}
                        disabled={selectionsAreUnchangedOrDefault(true)}
                    >
                        Reset
                    </Button>
                    <div>
                        <Button key="back" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            key="submit"
                            type="primary"
                            onClick={() => {
                                saveSelections();
                                onClose();
                            }}
                            disabled={selectionsAreUnchangedOrDefault()}
                        >
                            Apply
                        </Button>
                    </div>
                </div>,
            ]}
        >
            <div style={{ marginLeft: '10px' }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '20px',
                    }}
                >
                    <div
                        style={{
                            display: 'inline-block',
                            marginRight: '20px',
                        }}
                    >
                        {getComparisonTypeSelect(
                            getUserSelectionFor,
                            comparisonTypeOptions,
                            handleOnChange,
                            'Column 1',
                            PRIMARY_COLUMN_INDEX
                        )}
                    </div>

                    {getPeriodicitySelect(
                        buildStringToComparisonTypeString([
                            ...getUserSelectionFor(COMPARISON_TYPE),
                        ]),
                        getUserSelectionFor,
                        handleOnChange
                    )}

                    {getPeriodRangeSelect(
                        getUserSelectionFor,
                        rangeSelectIsOpen,
                        setRangeSelectIsOpen,
                        handleOnChange,
                        handleOnChangeDateRange
                    )}
                    {showYearEndingSelect() ? (
                        <div
                            style={{
                                display: 'inline-block',
                            }}
                        >
                            {getYearEndingSelect(
                                getUserSelectionFor,
                                handleOnChange,
                                clientFinancialYearEnding
                            )}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '20px',
                        justifyContent: 'center',
                    }}
                >
                    <div
                        style={{
                            display: 'inline-block',
                            marginRight: '20px',
                        }}
                    >
                        {getComparisonTypeSelect(
                            getUserSelectionFor,
                            comparisonTypeOptionsWithNone,
                            handleOnChange,
                            'Column 2',
                            SECONDARY_COLUMN_INDEX
                        )}
                    </div>
                    {getComparisonPeriodSelect(
                        getUserSelectionFor,
                        localModes,
                        handleOnChange
                    )}
                    {getVarianceOptions(
                        getUserSelectionFor,
                        handleOnChange,
                        getUserSelectionFor(COMPARISON_TYPE)[1] === NONE_VALUE
                    )}
                    <div>
                        {localSelections?.comparison_type ? (
                            getVarianceComparisonOptions(
                                getUserSelectionFor,
                                handleOnChange,
                                isVarianceComparisonSelectDisabled(
                                    VARIANCE_DISPLAY
                                ),
                                localSelections.comparison_type,
                                SECONDARY_COLUMN_INDEX
                            )
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '20px',
                        justifyContent: 'center',
                        marginBottom: '50px',
                    }}
                >
                    <div
                        style={{
                            display: 'inline-block',
                            marginRight: '20px',
                        }}
                    >
                        {getComparisonTypeSelect(
                            getUserSelectionFor,
                            comparisonTypeOptionsWithNone,
                            handleOnChange,
                            'Column 3',
                            TERTIARY_COLUMN_INDEX
                        )}
                    </div>
                    {getComparisonPeriodSelect(
                        getUserSelectionFor,
                        localModes,
                        handleOnChange,
                        TERTIARY_COLUMN_INDEX
                    )}
                    {getVarianceOptions(
                        getUserSelectionFor,
                        handleOnChange,
                        getUserSelectionFor(COMPARISON_TYPE)[2] === NONE_VALUE,
                        TERTIARY_COLUMN_INDEX
                    )}
                    <div>
                        {localSelections?.comparison_type ? (
                            getVarianceComparisonOptions(
                                getUserSelectionFor,
                                handleOnChange,
                                isVarianceComparisonSelectDisabled(
                                    VARIANCE_DISPLAY_TERTIARY,
                                    true
                                ),
                                localSelections?.comparison_type,
                                TERTIARY_COLUMN_INDEX
                            )
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default IncomeStatementMenu;
