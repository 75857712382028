import {
    AttributeFromAPI,
    EntityAttributesContextType,
} from 'contexts/entity-attributes/EntityAttributesTypes';
import { MutableRefObject, useContext, useRef } from 'react';
import { EntityAttributesContext } from 'contexts';
import { isEqual } from 'lodash';
import { AttributeDefinition } from 'waypoint-types';

export const useGetGroupableAttributes = (): AttributeFromAPI[] => {
    const entityAttributesContext =
        useContext<EntityAttributesContextType | null>(EntityAttributesContext);

    if (!entityAttributesContext) {
        throw Error(
            'useGetGroupableAttributes must be used within an EntityAttributesContextProvider',
        );
    }

    const groupableAttributes: AttributeDefinition[] | undefined =
        entityAttributesContext?.data?.attributeDefinitions.filter(
            (attribute) => attribute.is_groupable,
        );

    const uniqueAttributes: AttributeFromAPI[] = Object.values(
        (groupableAttributes ?? []).reduce(
            (
                result: { [key: string]: AttributeFromAPI },
                attribute: AttributeDefinition,
            ) => {
                result[attribute.attribute_code] = {
                    dataIndex: attribute.attribute_code,
                    key: attribute.attribute_code,
                    title: attribute.name,
                };

                return result;
            },
            {},
        ),
    );

    const groupableAttributesRef: MutableRefObject<AttributeFromAPI[]> =
        useRef<AttributeFromAPI[]>(uniqueAttributes);

    if (!isEqual(groupableAttributesRef.current, uniqueAttributes)) {
        groupableAttributesRef.current = uniqueAttributes;
    }

    return groupableAttributesRef.current;
};
