import React, { useState } from 'react';
import { css } from 'emotion';
import { Layout } from 'antd';
import theme from 'config/theme';
import Navigation from 'components/app/layout/navigation/Navigation';
import SubBar from 'components/app/SubBar';
import { EntityAttributesProvider, SavedFiltersProvider } from 'contexts';
import UserMenu from './user/UserMenu';
import SidebarDrawer from './SidebarDrawer';
import { siderZIndex } from './AppLayoutUtils';
import { PreloadData } from 'waypoint-react/PreloadData';

const { Content, Header, Sider } = Layout;

const quickFiltersContainer = (hideGlobalFilterBar: boolean) => {
    return css({
        right: 0,
        fontFamily: 'Lato',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'absolute',
        height: '50px',
        backgroundColor: theme.colors.white,
        width: '100%',
        paddingLeft: '10px',
        borderBottom: hideGlobalFilterBar
            ? 'none'
            : `1px solid ${theme.colors.grays.light}`,
    });
};

interface AppLayoutParams {
    hideGlobalFilterBar: boolean;
    hideDataLevelSelection: boolean;
    children: React.ReactNode;
}

const AppLayout = ({
    hideGlobalFilterBar,
    hideDataLevelSelection,
    children,
}: AppLayoutParams) => {
    const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(true);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState('');

    const showDrawer = (menuKey: string) => {
        setSelectedMenu(menuKey);
        setDrawerOpen(true);
    };

    const onClose = () => {
        setDrawerOpen(false);
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                collapsible
                width={220}
                collapsed={sidebarCollapsed}
                collapsedWidth={60}
                style={{
                    height: '100vh',
                    position: 'sticky',
                    zIndex: siderZIndex,
                    top: 0,
                    overflowY: 'auto',
                }}
                onCollapse={(collapsed) => setSidebarCollapsed(collapsed)}
            >
                <div
                    style={{
                        display: 'flex',
                        height: '93vh',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <Navigation
                        collapsed={sidebarCollapsed}
                        showDrawer={showDrawer}
                    />
                    <UserMenu
                        collapsed={sidebarCollapsed}
                        showDrawer={showDrawer}
                    />
                </div>
            </Sider>
            <Layout style={{ position: 'sticky', height: '100%' }}>
                <Header className={quickFiltersContainer(hideGlobalFilterBar)}>
                    <EntityAttributesProvider>
                        <SavedFiltersProvider>
                            <SubBar
                                hideGlobalFilters={hideGlobalFilterBar}
                                hideDataLevelSelection={hideDataLevelSelection}
                            />
                        </SavedFiltersProvider>
                    </EntityAttributesProvider>
                </Header>
                <Content style={{ height: '100%' }}>
                    <div
                        style={{
                            fontFamily: 'Lato',
                            backgroundColor: theme.colors.white,
                        }}
                    >
                        {children}
                    </div>
                    <SidebarDrawer
                        selectedMenu={selectedMenu}
                        drawerOpen={drawerOpen}
                        onClose={onClose}
                    />
                </Content>
            </Layout>
            <PreloadData />
        </Layout>
    );
};

export default AppLayout;
