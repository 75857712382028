import { RecurringChargeData, SelectedDataLevel } from 'waypoint-types';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import useSWR from 'swr';
import { getRecurringCharges } from 'waypoint-requests';

export const useRecurringCharges = (
    entityCodes: string[],
    selectedDataLevel: SelectedDataLevel,
): DataHookResponse<RecurringChargeData> => {
    const recurringChargesCacheKey = `/leases/recurring-charges/${entityCodes?.join(
        ',',
    )}/${selectedDataLevel.percentageType}/${selectedDataLevel.stakeholder}`;

    const { data, error, mutate, isValidating } = useSWR(
        entityCodes.length ? recurringChargesCacheKey : null,
        () => getRecurringCharges(entityCodes, selectedDataLevel),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        },
    );

    return {
        isLoading: !data,
        isError: !!error,
        data,
        mutate,
        isValidating,
    };
};
