import { RentRollProps, SelectedDataLevel } from 'waypoint-types';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import useSWR from 'swr';
import { getRentRollFull } from 'waypoint-requests';

export const useRentRoll = (
    entityCodes: string[],
    selectedDataLevel: SelectedDataLevel,
): DataHookResponse<RentRollProps[]> => {
    const rentRollCacheKey = `/leases/rent-roll/${entityCodes.join(',')}/${
        selectedDataLevel.percentageType
    }/${selectedDataLevel.stakeholder}`;

    const { data, error, mutate, isValidating } = useSWR(
        entityCodes.length ? rentRollCacheKey : null,
        () => getRentRollFull(entityCodes, selectedDataLevel),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        },
    );

    return {
        isLoading: !data,
        isError: !!error,
        data: data,
        mutate,
        isValidating,
    };
};
