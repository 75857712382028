import React, { useState, useRef } from 'react';
import { css, cx } from 'emotion';
import { Moment } from 'moment';

import theme from 'config/theme';
import { Button, Space } from 'antd';
import { RangePicker } from 'waypoint-react';

const rangeSelect = css`
    .ant-input {
        display: flex;
    }
`;

const background = css`
    padding: 10px;
    background-color: ${theme.colors.grays.background};
`;

const container = css`
    display: flex;
    justify-content: space-between;
    .ant-calendar-picker {
        width: 100%;
    }
`;

interface RangeSelectProps {
    value: any;
    open?: boolean;
    onFocus: () => void;
    onApply?: () => void;
    onCancel: () => void;
    displayBackground?: boolean;
    format?: string | undefined;
    onConfirm: (values: Moment[]) => void;
    pickerPeriod?:
        | 'time'
        | 'date'
        | 'week'
        | 'month'
        | 'quarter'
        | 'year'
        | undefined;
    [x: string]: any; // for props spread
}

const CONTAINER_ID: string = 'range_picker_container';

const RangeSelectConfirm = ({
    open,
    value,
    format,
    onApply,
    onCancel,
    onConfirm,
    pickerPeriod,
    displayBackground,
    ...rest
}: RangeSelectProps) => {
    const [values, setValues] = useState<any>(value);

    return (
        <div
            id={CONTAINER_ID}
            className={
                displayBackground ? cx([container, background]) : container
            }
        >
            <RangePicker
                open={open}
                value={value}
                separator="-"
                allowClear={false}
                className={rangeSelect}
                format={format || 'MMM YYYY'}
                picker={pickerPeriod || 'month'}
                onPanelChange={(momentObjects) => {
                    const sorted = momentObjects?.sort(
                        (a, b) => (a?.valueOf() ?? 0) - (b?.valueOf() ?? 0)
                    );
                    setValues(sorted);
                }}
                renderExtraFooter={() => {
                    return (
                        <Space style={{ float: 'right', padding: '5px' }}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    onConfirm(values);
                                }}
                            >
                                Ok
                            </Button>
                            <Button danger onClick={onCancel}>
                                Cancel
                            </Button>
                        </Space>
                    );
                }}
                {...rest}
            />
        </div>
    );
};

export default RangeSelectConfirm;
