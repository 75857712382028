import {
    useGetSelectedFilteredEntityCodes,
    useSelectedDataLevel,
} from 'waypoint-hooks';
import { getRentRollFull, getRecurringCharges } from 'waypoint-requests';
import preload from 'swr';
import { EntityAttributesProvider } from '../contexts';

export const PreloadData = () => {
    return (
        <EntityAttributesProvider>
            <RentRollData />
        </EntityAttributesProvider>
    );
};

const RentRollData = () => {
    const entityCodes: string[] = useGetSelectedFilteredEntityCodes();
    const selectedDataLevel = useSelectedDataLevel();

    const entityCodeKey = entityCodes?.join(',');

    preload(
        `/leases/rent-roll/${entityCodeKey}/${
            selectedDataLevel.percentageType
        }/${selectedDataLevel.stakeholder}`,
        () => getRentRollFull(entityCodes, selectedDataLevel),
    );

    preload(
        `/leases/recurring-charges/${entityCodeKey}/${selectedDataLevel.percentageType}/${selectedDataLevel.stakeholder}`,
        () => getRecurringCharges(entityCodes, selectedDataLevel),
    );

    return null;
};
