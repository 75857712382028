import React, { useEffect } from 'react';
import { css } from 'emotion';
import theme from 'config/theme';
import { useBalanceSheetSettings } from 'waypoint-hooks';
import {
    getBalanceSheetPrimaryMonthPicker,
    getBalanceSheetSecondaryMonthPicker,
} from 'components/financials/balanceSheet/components/selections/BalanceSheetSelectionUtils';
import {
    AvailabilityDateType,
    SelectedPeriodType,
} from 'components/financials/balanceSheet/types';
import { Button, Modal } from 'antd';

const buttonStyle = css`
    margin-right: 24px;
    &.ant-btn-primary:enabled {
        color: ${theme.colors.white} !important;
        background-color: ${theme.colors.blues.primary} !important;
        border-color: ${theme.colors.blues.outline} !important;
    }
`;

const modalStyle = css`
    width: 60rem !important;
    .ant-modal-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1 {
            margin: 0 !important;
        }
    }
    .ant-modal-footer {
        margin-top: 24px !important;
        text-align: center;
    }
`;

interface BalanceSheetSettingsMenuProps {
    dataAvailabilityDate: AvailabilityDateType;
    isOpen: boolean;
    entityCodes: string[];
    onClose: () => void;
    onReset: () => void;
    onApply: (selectedPeriod: SelectedPeriodType) => void;
    onClearFilters: () => void;
}

const BalanceSheetSettingsMenu = ({
    onClose,
    entityCodes,
    dataAvailabilityDate,
    onReset,
    onApply,
    isOpen,
    onClearFilters,
}: BalanceSheetSettingsMenuProps) => {
    const {
        disabledDate,
        primaryPeriod,
        setPrimaryPeriod,
        secondaryPeriod,
        setSecondaryPeriod,
    } = useBalanceSheetSettings(entityCodes);

    useEffect(() => {
        setPrimaryPeriod(dataAvailabilityDate.primary);
        setSecondaryPeriod(dataAvailabilityDate.secondary);
    }, [dataAvailabilityDate]);

    const isSelectedChanged = (isReset = false): boolean => {
        if (isReset) {
            return (
                dataAvailabilityDate.primaryDefault !== primaryPeriod ||
                dataAvailabilityDate.secondaryDefault !== secondaryPeriod
            );
        }
        return (
            dataAvailabilityDate.primary !== primaryPeriod ||
            dataAvailabilityDate.secondary !== secondaryPeriod
        );
    };

    const saveSelections = (): void => {
        onApply({
            selectedPrimaryPeriod: primaryPeriod ?? '',
            selectedSecondaryPeriod: secondaryPeriod ?? '',
        });
        onClearFilters();
    };

    return (
        <Modal
            className={modalStyle}
            title={
                <>
                    <h1>Balance Sheet Settings</h1>
                    <Button
                        className={buttonStyle}
                        type="primary"
                        onClick={() => {
                            onReset();
                            onClose();
                            onClearFilters();
                        }}
                        disabled={!isSelectedChanged(true)}
                    >
                        Reset
                    </Button>
                </>
            }
            onCancel={onClose}
            okText="Apply"
            onOk={() => {
                saveSelections();
                onClose();
            }}
            okButtonProps={{
                disabled: !isSelectedChanged(),
            }}
            open={isOpen}
        >
            <div style={{ display: 'inline-block', marginRight: '20px' }}>
                {getBalanceSheetPrimaryMonthPicker(
                    disabledDate,
                    primaryPeriod,
                    setPrimaryPeriod
                )}
            </div>
            <div style={{ display: 'inline-block', marginRight: '20px' }}>
                {getBalanceSheetSecondaryMonthPicker(
                    disabledDate,
                    secondaryPeriod,
                    setSecondaryPeriod
                )}
            </div>
        </Modal>
    );
};

export default BalanceSheetSettingsMenu;
